<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>报修任务</h1>
      </el-header>
      <el-main class="scrollable-main">
        <!-- 添加筛选框 -->
        <el-form :inline="true" class="filter-form">

          <el-form-item label="选择车间">
            <el-select v-model="selectedLine" clearable placeholder="请选择车间" @change="handleLineChange">
              <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <template v-if="reportsWait.length === 0">
          <div class="no-data">暂无报修任务</div>
        </template>
        <template v-else>
          <el-card v-for="item in filteredReports" :key="item.id" style="margin-bottom: 10px;">
            <div slot="header">
              <div>
                {{ getLineName(item.lineId) }} | {{ item.equipmentName }}
              </div>
              <div>
                <el-tag :type="getTagType(item.state)" size="medium" style="margin-left: 40px">{{ item.state }}</el-tag>
                <el-button style="float: right;" @click="goToDetail(item.id)">查看</el-button>
                <el-button v-if="userRole !== 'ROLE_USER' && userRole !== 'ROLE_MAINTAINER' && item.state === '等待中'" style="float: right;" @click="giveTask(item)">安排维修工</el-button>
              </div>
            </div>
            <div>
              <div><span>报修人：{{ getUserName(item.userId) }}</span></div>
              <div><span>报修人电话：{{ getUserPhone(item.userId) }}</span></div>
              <div><span>报修时间：{{ item.recordTime }}</span></div>
              <div><span>故障类型:{{ item.failureType }}</span></div>
              <div><span>故障描述:{{ item.description }}</span></div>
            </div>
          </el-card>
        </template>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>

    <el-dialog title="安排维修工" :visible.sync="dialogFormVisible" width="90%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="维修工">
          <el-select clearable v-model="form.userId" placeholder="请选择维修工" style="width: 100%">
            <el-option v-for="item in maintainers" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "MReportList",
  data() {
    return {
      reports: [],
      lines: [],
      users: [],
      reportsWait: [],
      maintainers: [],
      selectedLine: null,
      form: {},
      userRole: "",
      dialogFormVisible: false,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    };
  },
  created() {
    this.load();
  },
  computed: {
    filteredReports() {
      return this.getFilteredReports();
    }
  },
  methods: {
    // 获取数据
    load() {
      this.request.get("/repairReport/waiting").then(res => {
        this.reportsWait = res.data;
      });
      this.request.get("/repairReport").then(res => {
        this.reports = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
        this.userRole = this.users.find(item => item.id === this.user.id).role;
      });
      this.request.get("/user/listMaintainer").then(res => {
        this.maintainers = res.data;
      });
    },
    getTagType(state) {
      // 根据状态返回不同的标签类型（颜色）
      switch (state) {
        case '等待中':
          return 'danger';
        case '已接单':
          return 'info';
        case '作业结束':
          return 'success';
        case '维修中':
          return '';
        case '暂停中':
          return 'warning';
      }
    },
    handleLineChange() {
      this.filteredReports = this.getFilteredReports();
    },
    getFilteredReports() {
      let filtered = this.reportsWait;

      if (this.selectedLine) {
        filtered = filtered.filter(item => item.lineId === this.selectedLine);
      }

      return filtered;
    },
    getLineName(lineId) {
      const line = this.lines.find(e => e.id === lineId);
      return line ? line.name : '';
    },
    getUserName(userId) {
      const user = this.users.find(u => u.id === userId);
      return user ? user.nickname : '';
    },
    getUserPhone(userId) {
      const user = this.users.find(u => u.id === userId);
      return user ? user.phone : '';
    },
    goToDetail(reportId) {
      console.log('Clicked item id:', reportId);
      this.$router.push({ name: 'MReportDetail', params: { id: reportId } });
    },
    giveTask(report){
      this.form = report
      this.dialogFormVisible = true
    },
    goToHome() {
      this.$router.push('/mobile');
    },
    save(){
      this.request.get("/failureRecord/findByReportId/"+this.form.id).then(res => {
        if (res.data) {
          this.$message.error("该工单已经被接单");
        } else {
          const formData = {
            reportId: this.form.id,
            userId: this.form.userId, // 接单人的 id
            equipmentId: this.form.equipmentId,
            equipmentName: this.form.equipmentName,
            type: this.form.type,
            failureType: this.form.failureType,
            partId: this.form.partId,
            lineId: this.form.lineId,
          };
          this.request.post("/failureRecord", formData).then(res => {
            if (res.code === '200') {
              this.$message.success("接单成功")
              this.dialogFormVisible = false
              this.$router.push('/mobile');
            } else {
              this.$message.error("接单失败")
            }
          })
          this.request.post("/repairReport/start/" + this.form.id)
          //this.request.post("/user/start/" + this.user.id)
        }
      })
    }
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.el-header {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.el-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
